import { deepClone } from '@/utils/utils.js'

import enums from "@/enums.js";
const { FormatType } = enums;
const state = {
  placeholderUrl: "",
  videoOptions: {
    autoplay: true,
    // fluid => no black borders, player adapts to video dimensions.
    fluid: true,
    // user always active by default can be changed with player.userActive()
    inactivityTimeout: 0,
    controls: true,
    muted: true,
    loadingSpinner: true,
    userActions: {
      doubleClick: false,
    },
    controlBar: {
      captionsButton: false,
      pictureInPictureToggle: false,
      fullscreenToggle: true,
      playbackRateMenuButton: true,
      volumePanel: false,
      muteToggle: true,
    },
    sources: [

    ],
  }
};

const mutations = {
  SET_PLACEHOLDER_VIDEO(state, url) {
    state.placeholderUrl = url;
  },

  SET_VIDEO_OPTIONS(state, options) {
    state.videoOptions = options;
  },


};

const getters = {
  placeholderUrl: (state) => state.placeholderUrl,

};


const actions = {
  async createBlobUrl({ commit }) {
    const videoLocation = "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/2bfc05cb-5231-493c-a122-f1f44025d323/img_1585_1_.mov-compressed-short.mp4";
    try {
      // Fetch the video file and convert the response to a Blob
      const response = await fetch(videoLocation);
      const blob = await response.blob();
      // Create an object URL for the Blob
      commit("SET_PLACEHOLDER_VIDEO", URL.createObjectURL(blob));

    } catch (error) {
      console.error("Error fetching video file:", error);
      return null;
    }
  },

  async setVideoSources({ getters, rootState }, { video, isCurrentVideo }) {
    const sourcesArray = [];

    if (isCurrentVideo) {
      // for shorter videos we play the mp4 directly because it loads faster and doesn't consume more bandwidth
      if (rootState.player.isMobile && video.videoDuration > 30 && video.mobileStreamingUrl) {
        sourcesArray.push({
          src: video.mobileStreamingUrl,
          type: "application/x-mpegURL",
        });
      }

      if (video.videoDuration > 30 && video.streamingUrl) {
        sourcesArray.push({
          src: video.streamingUrl,
          type: "application/x-mpegURL",
        });
      }

      if (video.url) {
        sourcesArray.push({
          src: video.url,
          type: "video/mp4",
        });
      }

      if (video.trimmedUrl) {
        sourcesArray.push({
          src: video.trimmedUrl,
          type: "video/mp4",
        });
      }
    } else {
      sourcesArray.push({
        src: getters.placeholderUrl,
        type: "video/mp4",
      });
    }

    return sourcesArray;

  },


  async createVideoOptions({ dispatch }, { format, isCurrentVideo, video }) {
    const baseVideoJsOptions = deepClone(state.videoOptions)
    if (format.formatType === FormatType.carousel || format.formatType === FormatType.embed) {
      baseVideoJsOptions.autoplay = false;
    }
    // fullscreen only on Mobile and ?embed?
    baseVideoJsOptions.controlBar.fullscreenToggle = false

    if (format?.hideControlBar ?? false) {
      {
        const { controlBar } = baseVideoJsOptions;
        controlBar.timeDivider = false;
        controlBar.durationDisplay = false;
        controlBar.progressControl = false;
        controlBar.remainingTimeDisplay = false;
        controlBar.playToggle = false;
      }
    }
    baseVideoJsOptions.sources = await dispatch("setVideoSources", { isCurrentVideo, video, format });
    return baseVideoJsOptions;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

