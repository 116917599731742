import Vue from "vue"
import VueRouter from 'vue-router'

import Home from '@/views/Home';
const isLibrary = process.env.VUE_APP_LIBRARY === "true"

!isLibrary && Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: "/:siteId/:campaignId",
      component: Home,
      name: 'Home'
    },
    {
      path: "/embed/:siteId/:campaignId",
      component: Home,
      name: 'Home'
    },
  ]
});

export default router;