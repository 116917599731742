import Vuex from "vuex";
import Vue from "vue";
Vue.use(Vuex);

import playerCampaign from './player-campaign'
import playerSite from './player-site'
import videos from './videos'
import player from './player'
import analytics from "./analytics";
import videoJS from "./videoJS";

const modules = {
  playerCampaign,
  playerSite,
  videos,
  player,
  analytics,
  videoJS
};

const store = new Vuex.Store({
  modules,
  namespaced: true,
  strict: process.env.NODE_ENV !== "production",
});

export { modules as playerStore };

export default store;
