import axios from "axios";

async function getCampaign({ siteId, campaignId }) {
  const requestOptions = {
    method: "get",
    headers: { "Content-Type": "application/json" },
    params: {
      siteId,
      campaignId,
    },
  };
  try {
    const requestUrl = `${process.env.VUE_APP_API_URL}/player-get-campaign`
    let res = await axios(
      requestUrl,
      requestOptions
    );


    return res.data
  } catch (err) {
    throw err.response.data;
  }
}


function sendError(errorLog) {
  fetch(`${process.env.VUE_APP_API_URL}/player-logs`, {
    method: "POST",
    keepalive: true,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(errorLog),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Success:", data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

function sendEvent(dataToSend) {
  fetch(`${process.env.VUE_APP_API_URL}/track-event`, {
    method: "POST",
    keepalive: true,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataToSend),
  })
}

async function checkout(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/checkout`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    window.top.location.href = document.referrer + "/cart";
    throw err.response.data;
  }
}


export default {
  getCampaign,
  sendError,
  sendEvent,
  checkout
};


