import api from '@/api/api.js';

const state = {
  analytics: {},
  buyNowProduct: {},
};

const mutations = {
  SET_ANALYTICS(state, { route, device }) {
    const eventData = {
      campaignId: route.params.campaignId,
      siteId: route.params.siteId,
      date: route.query.date ?? new Date().toISOString(),
      device
    }
    // these parameters aren't in the url of the embed format
    route.query.sessionId && (eventData.sessionId = route.query.sessionId);
    route.query.device && (eventData.device = route.query.device)
    state.analytics = eventData
  },

  ADD_ANALYTICS(state, data) {
    state.analytics = { ...state.analytics, ...data };
  },
  SET_BUY_NOW_PRODUCT(state, product) {
    state.buyNowProduct = product
  }

};

const getters = {
  hasFlowId: (state) => {
    return Boolean(state.analytics.flowId)
  },
  getBuyNowProduct: (state) => {
    return state.buyNowProduct
  },
  getUrl: (state) => {
    return state.analytics.url
  },
  getPageProductId: (state) => {
    return state.analytics.productId
  }
};




const actions = {
  sendEvent({ state }, eventType) {
    api.sendEvent({ ...state.analytics, ...eventType })
  },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

