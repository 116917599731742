class SwiperVidjet {
  constructor(startEvent, isTouchable, endEvent) {
    this.startEvent = startEvent;
    this.endEvent = endEvent || null;
    this.isTouchable = isTouchable || false;
  }

  isSwipeUp() {
    return this.getSwipeDirection() == "up";
  }

  isSwipeDown() {
    return this.getSwipeDirection() == "down";
  }

  getDeltaX() {
    let start = this.isTouchable
      ? this.startEvent.changedTouches[0]
      : this.startEvent;
    let end = this.isTouchable
      ? this.endEvent.changedTouches[0]
      : this.endEvent;
    if (!start || !end) {
      return null;
    }
    return this.isTouchable
      ? start.screenX - end.screenX
      : start.clientX - end.clientX;
  }

  getSwipeDirection() {
    const swipeThreshold = 50;
    let start = this.isTouchable
      ? this.startEvent.changedTouches[0]
      : this.startEvent;
    let end = this.isTouchable
      ? this.endEvent.changedTouches[0]
      : this.endEvent;
    if (!start || !end) {
      return null;
    }

    let verticalDifference = this.isTouchable
      ? start.screenY - end.screenY
      : start.clientY - end.clientY;
    if (verticalDifference >= swipeThreshold) {
      return "up";
    } else if (verticalDifference <= -swipeThreshold) {
      return "down";
    }
    return null;
  }

  setEndEvent(endEvent) {
    this.endEvent = endEvent;
  }
}

export default SwiperVidjet;
