import Vue from 'vue'
import visibility from 'vue-visibility-change';
import App from './App.vue'
import UUID from "vue-uuid";
import router from "./router";
import vSelect from "vue-select";
import { slide } from './utils/v-slide';
import { VidjetTooltip } from './utils/vidjet-tooltip';
import i18n from "./i18n";
import store from "./store";
import vClickOutside from 'v-click-outside'
Vue.config.productionTip = false

// custom directives
Vue.directive('slide', slide);
Vue.directive('tooltip', VidjetTooltip);

Vue.use(visibility);
Vue.component("VSelect", vSelect);
Vue.use(UUID);
Vue.use(vClickOutside)
new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
}).$mount('#app')



