import { deepClone } from "../utils/utils";
const state = {
  // videos: [{ WHY IS THIS HERE ?
  //   "_id": "df2e9f56-1966-4c06-a655-73bc2bae64b1",
  //   "siteId": "317eb20a-f226-4fec-b268-0c115fb14979",
  //   "videoDuration": 17.1,
  //   "height": 1920,
  //   "width": 1080,
  //   "fileName": "IMG_0412 (1).mp4",
  //   "url": "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/2bfc05cb-5231-493c-a122-f1f44025d323/img_1585_1_.mov-compressed-short.mp4",
  //   "thumbnail": "https://dev-image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/317eb20a-f226-4fec-b268-0c115fb14979/df2e9f56-1966-4c06-a655-73bc2bae64b1/1684850480941.jpg",
  //   "creationDate": "2023-05-23T14:01:20.980Z",
  //   "modificationDate": "2023-05-23T14:02:03.736Z",
  //   "thumbnailS3Path": "s3://dev-image-compression-pipeline-source/317eb20a-f226-4fec-b268-0c115fb14979/df2e9f56-1966-4c06-a655-73bc2bae64b1/1684850480941.jpg",
  //   "key": "317eb20a-f226-4fec-b268-0c115fb14979/df2e9f56-1966-4c06-a655-73bc2bae64b1/img_0412__1_.mp4",
  //   "s3Path": "s3://video-compression-pipeline-dev-source-lcmd7m0ebsm4/317eb20a-f226-4fec-b268-0c115fb14979/df2e9f56-1966-4c06-a655-73bc2bae64b1/img_0412__1_.mp4",
  //   "cta": {
  //     "text": "Text",
  //     "textColor": "#000000",
  //     "value": "",
  //     "buttonColor": "#000000",
  //     "ctaType": 1,
  //   },
  // },],
  videos: [],
  currentIndex: null,
  textOptionsPreview: {},
  hasMultipleVideos: false,
};

const getters = {
  videos: (state) => state.videos,
  currentVideo: (state) => state.videos[state.currentIndex],
  sizeCoefficient: (_, getters) => getters.currentVideo ? getters.currentVideo.width / getters.currentVideo.height : 1,
  isMultiple: (state) => state.videos.length > 1 || state.hasMultipleVideos,
  isSquare: (_, getters) => !getters.isPortrait && getters.currentVideo.width === getters.currentVideo.height,

  isPortrait: (_, getters, rootState) => {
    return rootState.playerCampaign.format.isFullScreen || ((getters.currentVideo.height > getters.currentVideo.width)
      || (getters.isMultiple && rootState.playerCampaign.format.formatType === 1))
  },
  isLandscape: (_, getters) => !getters.isPortrait && getters.currentVideo.width > getters.currentVideo.height,
  isLastVideo: (state) => state.currentIndex === state.videos.length - 1,
  isFirstVideo: (state) => state.currentIndex === 0,
  currentIndex: (state) => state.currentIndex,

  getIsCurrentVideoByIndex: (state) => index => state.currentIndex === index,
  getVideoByIndex: (state) => index => state.videos[index],
  getCtaByIndex: (state) => index => state.videos[index].cta || {},
  textOptionsPreview: (state) => state.textOptionsPreview,

  hasYoutubeThumbnail: (state) => {
    return (
      state.videos?.length && 
      state.videos[0].siteId === 'bf0156b9-c586-4d30-9056-ad435907277a' &&
      state.videos[0]?.cta?.products?.length && 
      state.videos[0]?.cta?.products[0].hasYoutubeVideos
    )
  }
};

const mutations = {
  SET_VIDEOS(state, videos) {
    state.videos = videos;
  },

  SET_CURRENT_INDEX(state, index) {
    state.currentIndex = index;
  },

  SET_TEXT_OPTIONS_PREVIEW(state, textOptions) {
    state.textOptionsPreview = textOptions;
  },
  SET_HAS_MULTIPLE_VIDEOS(state, hasMultipleVideos) {
    state.hasMultipleVideos = hasMultipleVideos;
  },
};



const actions = {
  reorganizeVideos({ commit }, { index, videos }) {
    let newVideos = deepClone(videos);
    newVideos = [
      ...newVideos.splice(index),
      ...newVideos.splice(0, index),
    ];
    commit('SET_VIDEOS', newVideos);
  },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};


