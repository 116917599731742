const state = {
  site: {},
};

const mutations = {
  SET_SITE(state, site) {
    state.site = site;
  }
};

const getters = {
  showWatermark: (state) => state.site.showWatermark,
  site: (state) => state.site,
  currency: (state) => state.site.currency || "USD",
  integration: (state) => state.site.integration
};


export default {
  namespaced: true,
  state,
  getters,
  mutations,
};

