export const VidjetTooltip = {
  bind(element, binding) {
    let {
      text,
      trigger = "hover",
      shouldShowTooltip = true,
      position = "top",
    } = binding.value;
    const tooltip = document.createElement("div");
    tooltip.classList.add("tooltip");
    Object.assign(tooltip.style, {
      position: "fixed",
      backgroundColor: "rgba(23, 23, 23, 0.9)",
      color: "white",
      padding: "5px",
      borderRadius: "4px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
      zIndex: "9999",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      fontSize: "12px",
      fontWeight: "semi-bold",
      lineHeight: "16px",
      whiteSpace: "nowrap",
      fontFamily: "Montserrat, sans-serif",
      display: "none", // Initially hide the tooltip
    });

    const updateTooltipText = () => {
      tooltip.textContent = text;
    };

    const showTooltip = () => {
      if (shouldShowTooltip) {
        const rect = element.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0;
        const tooltipWidth = tooltip.offsetWidth;
        const tooltipHeight = tooltip.offsetHeight;
        let tooltipTop, tooltipLeft;

        switch (position) {
          case "top":
            tooltipTop = rect.top + scrollTop - tooltipHeight - 30;
            tooltipLeft = rect.left + rect.width / 2 - tooltipWidth / 2;
            break;
          case "topClose":
            tooltipTop = rect.top + scrollTop - tooltipHeight - 20;
            tooltipLeft = rect.left + rect.width / 2 - tooltipWidth / 2;
            break;
          case "bottom":
            tooltipTop = rect.top + scrollTop + rect.height + 10;
            tooltipLeft = rect.left + rect.width / 2 - tooltipWidth / 2;
            break;
          case "left":
            tooltipTop =
              rect.top + scrollTop + rect.height / 2 - tooltipHeight / 2;
            tooltipLeft = rect.left - tooltipWidth - 45;
            break;
          case "right":
            tooltipTop =
              rect.top + scrollTop + rect.height / 2 - tooltipHeight / 2;
            tooltipLeft = rect.left + rect.width + 45;
            break;
          default:
            tooltipTop = rect.top + scrollTop - tooltipHeight - 30;
            tooltipLeft = rect.left + rect.width / 2 - tooltipWidth / 2;
        }

        tooltip.style.top = tooltipTop + "px";
        tooltip.style.left = tooltipLeft + "px";

        tooltip.style.display = "block";

        document.body.appendChild(tooltip);

        if (trigger === "click") {
          setTimeout(hideTooltip, 2000);
        }
      }
    };

    const hideTooltip = () => {
      tooltip.style.display = "none";
      tooltip.remove();
    };

    const updateTooltipBehavior = () => {
      if (shouldShowTooltip && trigger === "hover") {
        element.addEventListener("mouseenter", showTooltip);
        element.addEventListener("mouseleave", hideTooltip);
      } else if (shouldShowTooltip && trigger === "click") {
        element.addEventListener("click", showTooltip);
      } else {
        element.removeEventListener("mouseenter", showTooltip);
        element.removeEventListener("mouseleave", hideTooltip);
        element.removeEventListener("click", showTooltip);
      }
    };

    // Initial tooltip setup
    updateTooltipText();
    updateTooltipBehavior();

    // Watch for changes in shouldShowTooltip property
    element.vueTooltipWatcher = element.vueTooltipWatcher || {};
    element.vueTooltipWatcher.shouldShowTooltip = (newValue) => {
      shouldShowTooltip = newValue;
      updateTooltipBehavior();
    };

    // Watch for changes in text property
    element.vueTooltipWatcher.text = (newValue) => {
      text = newValue;
      updateTooltipText();
    };

    // Cleanup the watchers on unbind
    element.vueTooltipWatcher.unbind = () => {
      element.removeEventListener("mouseenter", showTooltip);
      element.removeEventListener("mouseleave", hideTooltip);
      element.removeEventListener("click", showTooltip);
      element.vueTooltipWatcher = null;
    };
  },

  update(element, binding) {
    const { text, shouldShowTooltip = true } = binding.value;
    if (element.vueTooltipWatcher) {
      if (element.vueTooltipWatcher.shouldShowTooltip) {
        element.vueTooltipWatcher.shouldShowTooltip(shouldShowTooltip);
      }
      if (element.vueTooltipWatcher.text) {
        element.vueTooltipWatcher.text(text);
      }
    }
  },

  unbind(element) {
    if (element.vueTooltipWatcher && element.vueTooltipWatcher.unbind) {
      element.vueTooltipWatcher.unbind();
    }
  },
};
