var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showPlayers)?_c('main',{directives:[{name:"visibility-change",rawName:"v-visibility-change",value:(_vm.sendFlowDurationOnVisibilityChange),expression:"sendFlowDurationOnVisibilityChange"}],class:[
    'main-wrapper',
    {
      isPopup: _vm.isPopup,
      isMobile: _vm.isMobile,
      isEmbed: _vm.isEmbed,
      isFullScreen: _vm.isFullScreen,
      isPortrait: _vm.isPortrait,
      isLandscape: _vm.isLandscape,
      isBubble: _vm.isBubble,
      isMultiple: _vm.isMultiple,
      isDesktop: !_vm.isMobile,
      isHorizontalSwipe: _vm.isHorizontalSwipe && _vm.isPlayerOpened,
    },
    { isCarousel: _vm.showCarousel },
  ],style:(_vm.isMobile && !_vm.isInPreviewComponent && 'height: calc(var(--vh, 1vh) * 100);')},[_c('transition',{attrs:{"name":"close-button"}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.isLoading)?_c('SpinnerLoader',{staticClass:"spinner",attrs:{"background":_vm.backgroundColor}}):_vm._e()],1),(!_vm.showCarousel)?_c('div',{staticClass:"format-container",style:([_vm.isLoading ? { opacity: 0 } : ''])},[_c('div',{directives:[{name:"slide",rawName:"v-slide",value:({
        numberOfVideos: _vm.videos.length,
        isMobile: _vm.isMobile && !_vm.isTablet,
        isInPreview: _vm.isInPreviewComponent,
        isSlidable: _vm.isSlidable,
        index: _vm.currentIndex,
        isHorizontalSwipe: _vm.isHorizontalSwipe,
      }),expression:"{\n        numberOfVideos: videos.length,\n        isMobile: isMobile && !isTablet,\n        isInPreview: isInPreviewComponent,\n        isSlidable: isSlidable,\n        index: currentIndex,\n        isHorizontalSwipe: isHorizontalSwipe,\n      }"}],staticClass:"v-slide-wrapper",style:(_vm.isHorizontalSwipe &&
        `grid-template-columns: repeat(${_vm.videos.length}, 100%);`)},_vm._l((_vm.videos),function(video,index){return _c('div',{key:video.id,staticClass:"player-wrapper"},[(!_vm.isBubble)?_c('video-player',{staticClass:"video-player",attrs:{"video-index":index},on:{"notify-preview":function($event){return _vm.$emit('notify-preview')}}}):_c('BubbleFormat',{ref:"bubble",refInFor:true,attrs:{"videoIndex":index},on:{"notify-preview":function($event){return _vm.$emit('notify-preview')}}})],1)}),0)]):_c('div',{staticClass:"carousel-container",style:(_vm.justifyContentStyle)},[_c('CarouselFormat',{attrs:{"is-preview":_vm.isInPreviewComponent},on:{"open-full-screen":_vm.toggleFullScreenCarousel,"carousel-height":_vm.carouselChangeHeight}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }